/* General Styles */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #333;
  }
  
  .container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  h1, h2, h3 {
    margin: 0;
    padding: 0;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  .btn-primary, .btn-secondary {
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    transition: background 0.3s ease;
  }
  
  .btn-primary {
    background: #007BFF;
    color: #fff;
  }
  
  .btn-primary:hover {
    background: #0056b3;
  }
  
  .btn-secondary {
    background: #6c757d;
    color: #fff;
  }
  
  .btn-secondary:hover {
    background: #5a6268;
  }
  
  /* Header */
  header {
    background: rgba(51, 51, 51, 0.9);
    color: #fff;
    padding: 20px 0;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
  }
  
  header .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  header .logo h1 {
    font-size: 24px;
    margin: 0;
  }
  
  header nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 20px;
  }
  
  header nav ul li a {
    color: #fff;
    font-weight: bold;
    transition: color 0.3s ease;
  }
  
  header nav ul li a:hover {
    color: #007BFF;
  }
  
  /* Hero Section */
  .hero {
    background: url('https://images.unsplash.com/photo-1503376780353-7e6692767b70?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80') no-repeat center center/cover;
    color: #fff;
    padding: 150px 0;
    text-align: center;
    position: relative;
  }
  
  .hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay for better text readability */
  }
  
  .hero .container {
    position: relative;
    z-index: 1;
  }
  
  .hero h1 {
    font-size: 48px;
    margin-bottom: 20px;
  }
  
  .hero p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .cta-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  /* How It Works Section */
  .how-it-works {
    padding: 60px 0;
    text-align: center;
    background: #f8f9fa;
  }
  
  .how-it-works h2 {
    font-size: 36px;
    margin-bottom: 40px;
  }
  
  .steps {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }
  
  .step {
    flex: 1;
    max-width: 300px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .step i {
    font-size: 48px;
    margin-bottom: 20px;
    color: #007BFF;
  }
  
  .step h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  /* Features Section */
  .features {
    padding: 60px 0;
    text-align: center;
  }
  
  .features h2 {
    font-size: 36px;
    margin-bottom: 40px;
  }
  
  .feature-list {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }
  
  .feature {
    flex: 1;
    max-width: 300px;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .feature i {
    font-size: 48px;
    margin-bottom: 20px;
    color: #007BFF;
  }
  
  .feature h3 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  /* Pricing Section */
  .pricing {
    padding: 60px 0;
    text-align: center;
    background: #f8f9fa;
  }
  
  .pricing h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .pricing .subtitle {
    font-size: 18px;
    color: #666;
    margin-bottom: 40px;
  }
  
  .pricing-plans {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .plan {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 30px;
    flex: 1;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .plan:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .plan h3 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #007BFF;
  }
  
  .plan ul {
    list-style: none;
    padding: 0;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .plan ul li {
    margin-bottom: 15px;
    font-size: 16px;
    color: #333;
  }
  
  .plan ul li i {
    color: #007BFF;
    margin-right: 10px;
  }
  
  .plan .btn-primary {
    width: 100%;
    margin-top: 20px;
  }
  
  /* Contact Section */
  .contact {
    padding: 60px 0;
    text-align: center;
  }
  
  .contact h2 {
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .contact form {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .contact form input, .contact form textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .contact form button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background: #007BFF;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .contact form button:hover {
    background: #0056b3;
  }
  
  /* Footer */
  footer {
    background: #333;
    color: #fff;
    padding: 20px 0;
    text-align: center;
  }
  
  footer .social-links {
    margin-top: 10px;
  }
  
  footer .social-links a {
    color: #fff;
    margin: 0 10px;
    font-size: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .steps, .feature-list, .pricing-plans {
      flex-direction: column;
      align-items: center;
    }
  
    .hero h1 {
      font-size: 36px;
    }
  
    .hero p {
      font-size: 16px;
    }
  
    .cta-buttons {
      flex-direction: column;
      gap: 10px;
    }
  }