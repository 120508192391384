/* AddNewVehical.css */

.vehical_wrapper {
    background: #fff; /* Set your desired background color */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add box shadow for a subtle effect */
    padding: 20px;
    margin-top: 20px;
    transition: box-shadow 0.3s ease; /* Optional: Add transition for a smooth effect on hover */
  }
  
  /* Optional: Add hover effect */
  .vehical_wrapper:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  /* Rest of your existing styles */
  