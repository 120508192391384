@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: "Fira Sans", sans-serif;
}

.auth-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 83vh;
  background: #f7f7f7;
}

.auth-wrapper img {
  max-width: 200px; /* Increase the logo size */
  margin-bottom: 10px; /* Reduce the space between the logo and the Sign In block */
}

.auth-inner {
  width: 100%;
  max-width: 500px; /* Increase the width of the Sign In block */
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 100px rgba(34, 35, 58, 0.4);
  padding: 50px; /* Increase padding for larger form size */
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgotpassword,
.forgotpassword a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #fc0000;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

.error {
  color: red;
}
