@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}

/* body {
  background: #1c8ef9 !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
} */

.vehical-auth-wrapper {
  background-color: #ffffff;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.vehical-auth-inner {
  padding: 20px;
}

h3 {
  color: #333;
}
.vehical_wrapper{
  border-radius: 5px; /* Add border-radius to each row */
  border: 1px solid #ccc; /* Add a border for visual separation */
  padding: 10px; /* Add some padding for spacing */
  margin-bottom: 30px;
}

.vehical_row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.vehical_column {
  flex: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column; /* Display input fields in a column */
}

.form-label {
  font-weight: bold;
  color: #555;
}

input[type="number"],
select {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input[type="file"] {
  width: 100%;
  margin-top: 10px;
}
.image-preview {
  margin-top: 10px;
}

.image-preview img {
  max-width: 100px;
  max-height: 100px;
  margin-top: 5px;
}


.button_container {
  text-align: right;
  margin-top: -50px;
}

.btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.checkbox-row{
  display: flex;
  justify-content: space-around;
}
.checkbox-label {
  margin-left: 8px; /* Adjust the margin as needed */
}
.checkbox-item input{
 cursor: pointer;
}


.image-preview {
  margin-top: 10px;
  display: flex;
}
.remove-image{
  cursor: pointer;
}
.preview-image {
  max-width: 100px;
  max-height: 100px;
}
/* CSS for the dropzone container */
.dropzone {
  border: 2px dashed #ccc; /* Dashed border */
  padding: 20px; /* Add padding for better visual appearance */
  text-align: center; /* Center align text */
  cursor: pointer; /* Change cursor to pointer when hovering */
  background-color: #f9f9f9; /* Background color for the dropzone */
}

.dropzone p {
  margin: 0; /* Remove default margin for the paragraph */
}

/* Highlight the dropzone when hovering over it */
.dropzone:hover {
  border-color: #007bff; /* Change border color on hover */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .vehical_column {
      flex: 1 100%;
      margin-right: 0;
  }
}