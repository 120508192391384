@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}

/* body {
  background: #1c8ef9 !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Fira Sans", sans-serif;
} */

h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 500;
  font-family: "Fira Sans", sans-serif;
}

body,
html,
.App,
#root,
.register-auth-wrapper {
  width: 100%;
  height: 100%;
  margin: 0;
}

.register-auth-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-top: 10px;
}

.register-auth-inner {
  width: 100%;
  max-width: 850px;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 100px rgb(34 35 58 / 40%);
  padding: 30px;
  border-radius: 15px;
  transition: all 0.3s;
}

.register-auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.register-auth-wrapper h3 {
  text-align: center;
  margin: 0;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}
.register_row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Styling for each form column */
.register_column {
  flex: 1;
  margin-right: 10px;
}

/* Styling for form labels */
.form-label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Styling for form inputs */
.form-control {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

/* Highlight input border on focus */
.form-control:focus {
  border-color: #007bff;
}


.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.button_container {
  margin-top: 20px;
  text-align: end;
}

/* Styling for the submit button */
.btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Change button color on hover */
.btn:hover {
  background-color: #0056b3;
}
