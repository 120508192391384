.otp-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
  }
  
  .otp-inner {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .otp-inner h3 {
    margin-bottom: 1rem;
    color: #333;
  }
  
  .otp-timer {
    font-size: 14px;
    color: #888;
  }
  
  .otp-inner .form-label {
    font-weight: 500;
    text-align: left;
    display: block;
  }
  
  .otp-inner input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    font-size: 16px;
    text-align: center;
  }
  
  .otp-inner input.is-invalid {
    border-color: #dc3545;
  }
  
  .otp-inner .invalid-feedback {
    color: #dc3545;
    font-size: 14px;
    margin-top: 5px;
    text-align: left;
  }
  
  .otp-inner .btn {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    font-size: 16px;
    border-radius: 5px;
    transition: all 0.3s;
  }
  
  .otp-inner .btn-primary {
    background-color: #007bff;
    border: none;
  }
  
  .otp-inner .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .otp-inner .btn-link {
    color: #007bff;
    font-size: 14px;
    cursor: pointer;
    text-decoration: none;
  }
  
  .otp-inner .btn-link:hover {
    text-decoration: underline;
  }
  
  .alert {
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .alert-info {
    background-color: #e9f7fe;
    color: #0275d8;
    border: 1px solid #b8daff;
  }
  
  @media (max-width: 480px) {
    .otp-inner {
      width: 90%;
      padding: 1.5rem;
    }
  }
  