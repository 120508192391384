.edit_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: end;
}

.custom-control.material-switch .dropdown-toggle {
  box-shadow: none !important;
  padding: 0;
  color: #84818a;
  transform: scale(1.3);
  height: 24px;
}

.custom-control.material-switch .dropdown-toggle::after {
  display: none;
}

.custom-control.material-switch .notify a {
  color: #84818a;
  line-height: 30px;
}

.custom-control.material-switch .notify a img {
  margin: 0 15px 0 10px;
}

.custom-control.material-switch .notify label {
  margin: 0;
  cursor: pointer;
}

.custom-control.material-switch .material-switch-control-indicator {
  margin: 0 10px 0 0;
}

.dropdown-item:active {
  background-color: inherit !important;
}

/* Add some styles to make it look like a switch */
.material-switch-control-input {
  display: none; /* Hide the actual checkbox */
}

.material-switch-control-indicator {
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  display: inline-block;
  position: relative;
  transition: background-color 0.3s;
}

.material-switch-control-input:checked + .material-switch-control-indicator {
  background-color: #007bff; /* Change the background color when checked */
}

.material-switch-control-text {
  margin-left: 10px;
  vertical-align: middle;
  cursor: pointer;
}

.car_details_wrap {
  display: grid;
}
