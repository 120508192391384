/* Dashboard.css */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

/* Reset default margin and font */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

/* Container for the entire dashboard */
.dashboard-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Take full height of the viewport */
}

/* Top Navigation Bar styles */
.top-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1976d2; /* Primary color */
  color: white; /* Text color */
  padding: 10px 20px; /* Add padding for spacing */
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

/* List of navigation items */
.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.nav-list li {
  margin-right: 20px; /* Add spacing between navigation items */
}

.nav-list a {
  text-decoration: none;
  color: white; /* Text color */
  display: flex;
  align-items: center;
  font-size: 16px;
  transition: color 0.3s ease; /* Smooth color transition on hover */
}

.nav-list a:hover {
  color: #f0f0f0; /* Lighter text color on hover */
}

.nav-list .icon {
  margin-right: 8px; /* Add spacing between icon and text */
  font-size: 20px;
}

/* Dropdown styles */
.dropdown {
  position: relative;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #3585d4; /* Primary color */
  min-width: 160px;
  z-index: 1;
  border-radius: 4px; /* Add rounded corners */
  padding: 8px 0; /* Add padding for spacing */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add shadow for elevation */
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-content a {
  color: white; /* Text color */
  padding: 10px 20px; /* Add padding for spacing */
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease; /* Smooth hover transition */
}

.dropdown-content a:hover {
  background-color: #1565c0; /* Darker shade for hover effect */
}

/* Main content area */
.main-content {
  flex: 1; /* Take remaining space */
  padding: 20px; /* Add padding for spacing */
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .top-nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-list {
    margin-top: 20px;
  }

  .nav-list li {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .dropdown-content {
    top: 100%;
    left: 0;
  }
}
