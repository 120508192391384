.vehical_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.Vehical {
    flex-basis: calc(25% - 20px); /* 25% of the container width minus spacing */
    margin: 10px; /* Add spacing between the cards */
}

.tab_wrapper {
    margin-top: 20px;
    padding: 10px;
    width: 50%;
}


.modelHeading {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.modelBotton {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.closeBotton, .nextBotton {
  width: 48%;
}

.closeBotton {
  background-color: #dc3545;
  border-color: #dc3545;
}

.nextBotton {
  background-color: #007bff;
  border-color: #007bff;
}

.closeBotton:hover, .nextBotton:hover {
  background-color: #bb2d3b;
  border-color: #bb2d3b;
}