/* Booking.css */

.heading {
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px;
  color: #333;
}

/* Style for the search field container */
.searchField {
  margin-bottom: 20px;
  text-align: center;
}

/* Style for the search input */
.searchInput {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 300px;
  font-size: 16px;
  outline: none;
}

/* Style for the search input placeholder */
.searchInput::placeholder {
  color: #999;
}


/* Custom styling for DataGrid */
.MuiDataGrid-root {
  height: 100%; /* Set the height of the DataGrid container */
}

/* Style for DataGrid rows */
.MuiDataGrid-row {
  font-size: 14px;
  height: 40px; /* Set the height of each row */
}

/* Style for DataGrid header cells */
.MuiDataGrid-colCell {
  background-color: #f0f0f0; /* Background color for header cells */
  color: #333; /* Text color for header cells */
  font-weight: bold;
}

/* Hover effect for DataGrid rows */
.MuiDataGrid-row:hover {
  background-color: #f5f5f5; /* Change background color on hover */
}

/* Selected row styling */
.Mui-selected {
  background-color: #c8e6c9; /* Background color for selected row */
}
